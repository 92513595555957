import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { RouterModule } from '@angular/router';

export type ButtonColor = 'primary' | 'secondary';

@Component({
  standalone: true,
  selector: 'lumiron-button-link',
  templateUrl: 'button-link.component.html',
  styleUrl: './../button.component.css',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, RouterModule],
})
export class ButtonLinkComponent {
  /**
   * Color of the button
   */
  public color = input<ButtonColor>('primary');

  /**
   * Link for navigation
   */
  public link = input.required<string>();

  /**
   * Scroll to on navigation
   */
  public scrollTo = input<string | undefined>(undefined);
}
